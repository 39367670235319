var wipwrp = window.wipwrp || {};

wipwrp.sharingCostsModal = (function($,mzr,doc,win) {
    var SHARING_COSTS_OPTIONS = [
        {
            key: "never",
            charge_ship_rates: false,
            ship_rates_threshold: null,
        },
        {
            key: "always",
            charge_ship_rates: true,
            ship_rates_threshold: 0,
        },
        {
            key: "29",
            charge_ship_rates: true,
            ship_rates_threshold: 29,
        },
        {
            key: "39",
            charge_ship_rates: true,
            ship_rates_threshold: 39,
        },
        {
            key: "49",
            charge_ship_rates: true,
            ship_rates_threshold: 49,
        }
    ];

    function optionToShipRate(value) {
        return SHARING_COSTS_OPTIONS.find(function (opt) {
            return opt.key === value;
        }) || {};
    }

    function updateSharingCosts(selectedValue) {
        var opt = optionToShipRate(selectedValue);
        return fetch('/pimcore/updatesharingcosts/' + exhibitorId, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                charge_ship_rates: opt.charge_ship_rates,
                ship_rates_threshold: opt.ship_rates_threshold,
                show_sharing_costs: false,
            })
        }).then(function (res) { return res.json(); })
    }

    function fetchExhibitor() {
        function shipRateValue(data) {
            if (data.charge_ship_rates === null) return "";
            if (data.ship_rates_threshold === null) return "never";
            if (data.ship_rates_threshold === 0) return "always";
            return String(data.ship_rates_threshold);
        }


        var query = [
            '{ ',
            '    getExhibitorListing(filter: "{\\"code\\": {\\"$like\\": \\"', exhibitorId, '\\"}}") { ',
            '        edges { ',
            '            node { ',
            '                code ',
            '                charge_ship_rates ',
            '                ship_rates_threshold ',
            '                show_sharing_costs ',
            '            } ',
            '        } ',
            '    } ',
            '} ',
        ].join('');
        return fetch("/pimcore/exhibitorlisting", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ query })
        })
            .then(function (res) { return res.json(); })
            .then(function (res) {
                var data = res.data.getExhibitorListing.edges[0].node;
                var opt = optionToShipRate(shipRateValue(data));
                return Object.assign({}, opt, {
                    show_sharing_costs: data.show_sharing_costs,
                });
            });
    };


    var self = {};
    var modal = document.querySelector('#sharing-costs-modal');
    var msg = document.querySelector('#sharing-costs-msg');

    if (modal) {
        var exhibitorId = modal.dataset.exhibitorId;
        var valueInput = modal.querySelector('[data-value]');
        var submitBtn = modal.querySelector('[data-save]');
        var loader = modal.querySelector('[data-loader]');
    }

    function modalShow(data) {
        valueInput.value = data.key;
        modal.style.display = 'flex';
    }

    function modalClose() {
        modal.style.display = 'none';
        submitBtn.style.display = 'block';
        loader.style.display = 'none';
    }

    function modalShowLoader() {
        submitBtn.style.display = 'none';
        loader.style.display = 'block';
    }

    function msgShow(data) {
        var sharingValue = data.key;
        var msgText = msg.querySelector('[data-sharing-value="' + sharingValue + '"]');
        if (!msgText) throw new Error('Invalid sharing value: ' + sharingValue);
        msgText.style.display = 'inline';
        msg.style.display = 'block';
    }


    self.init = function () {
        if (!modal) return self;

        submitBtn.addEventListener('click', function () {
            var value = valueInput.value;
            modalShowLoader();
            updateSharingCosts(value).then(function () {
                modalClose();
            });
        });

        fetchExhibitor().then(function (data) {
            var show = data.show_sharing_costs;
            if (!show) return msgShow(data);
            modalShow(data);
        });
    }

    return self;
})(jQuery,Modernizr, document, window);